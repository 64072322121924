import React from "react";
import styled, { keyframes } from "styled-components";
import { Image, Row, Col } from "react-bootstrap";
import img from "../assets/next.png";
import "../components.css";

const floatEffect = keyframes`
0% { transform: translatey(0px); }
25% { transform: translatey(-10px); }
50% { transform: translatey(-20px); }
75% { transform: translatey(-10px); }
100% { transform: translatey(0px); }
`;

const Container = styled.div`
  .bigboy {
    margin: 1% 10.5% 0% 10.5%;
    text-align: center;
  }
  .image-animation {
    animation: ${floatEffect} infinite 5s linear;
    width: 90%;
    height: auto;
  }
`;

const HeaderText = styled.div`
  margin-top: 30%;
  text-align: left;
  ul {
    font-size: 1.1em;
    list-style: none;
    padding-left: 0em;
  }
`;

const ScrollDown = styled.div`
  .center-con {
    display: flex;
    height: 10vh;
    align-items: center;
    justify-content: center;
  }

  .round {
    transform: rotate(90deg);
    position: absolute;
    border: 2px solid #000;
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  #cta {
    width: 100%;
    position: absolute;
  }
  #cta .arrow {
    left: 23%;
    color: black;
  }
  .arrow {
    position: absolute;
    bottom: 0;
    margin-left: 2px;
    width: 12px;
    height: 12px;
    background-size: contain;
    top: 12px;
  }
  .segunda {
    margin-left: 8px;
  }
  .next {
    background-image: url(${img});
  }
`;

const VerticalLine = styled.div`
  background-image: linear-gradient(#000, #000);
  background-size: 1.7px 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 4em;
  margin-top: 2%;
`;

export default function HomeHeader() {
  return (
    <React.Fragment>
      <Container>
        <Row className="bigboy">
          <Col xs={12} sm={12} md={6}>
            <HeaderText>
              <h1>ainley peña.</h1>
              <ul>
                <li>Product Management Intern @ Uplift</li>
                <li>Product Manager @ UW Blueprint</li>
                <li>Certified AWS Solutions Architect</li>
                <li style={{ paddingTop: "1em" }}>
                  Currently seeking Summer 2022 internships!
                </li>
              </ul>
            </HeaderText>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Image
              fluid
              alt="header-image"
              className="image-animation"
              src={require("../assets/ap-header.png")}
            />
          </Col>
        </Row>
      </Container>
      {/* <ScrollDown>
        <div className="center-con">
          <div className="round">
            <div id="cta">
              <span className="arrow primera next "></span>
              <span className="arrow segunda next "></span>
            </div>
          </div>
        </div>
      </ScrollDown>
      <VerticalLine></VerticalLine> */}
    </React.Fragment>
  );
}
